import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import TracesHistory from "../shared/components/CardTraceFlow/TracesHistory.component";
import dagre from "dagre";
import { useSnackbar, withSnackbar } from "notistack";
import React, {
  createRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import ReactFlow, {
  Background,
  MarkerType,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import { get, post, remove } from "../shared/http/httpService";
import "./Flowgraph.css";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UndoIcon from "@mui/icons-material/Undo";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import Fab from "@mui/material/Fab";
import EdgeModal from "./Components/edgeModal.component";
import NodeModal from "./Components/nodeModal.component";
import { useLocation } from "react-router-dom";

import { useTheme } from "@mui/system";

import { Tooltip } from "@mui/material";
import { useTranslation, withTranslation } from "react-i18next";
import { defaultAgent } from "../shared/helper/agentsHelper";
import { parseFeatures } from "../shared/helper/features";
import { orderAlphabeticallyWithAttribute } from "../shared/helper/orderAlphabetically";
import { newObjectId } from "../shared/helper/validations";
import CustomNode from "./Components/CustomNode.component";
import DraggableEdge from "./Components/DraggableEdge.component";
import FlowgraphContext from "./FlowgraphContext";

const Flowgraph = (props) => {
  const theme = useTheme();
  const initialEdges = [{ id: "e1-2", source: "1", target: "2" }];
  const [agentSelected, setAgentSelected] = useState("");
  const [agentConfig, setAgentConfig] = useState([]);
  const [agentNames, setAgentNames] = useState([]);
  const [feature, setFeature] = useState({});
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [selectedEdges, setSelectedEdges] = useState([]);
  const [openPageDialog, setOpenPageDialog] = useState(false);
  const [openEditEdge, setOpenEditEdge] = useState(false);
  const [intents, setIntents] = useState([]);
  const [traces, setTraces] = useState([]);
  const [intentsqr, setIntentsqr] = useState([]);
  const [pagesLean, setPagesLean] = useState([]);
  const [editNode, setEditNode] = useState({ data: {} });
  const [editEdge, setEditEdge] = useState({ data: {} });
  const [reactFlowInstance, setReactFlowInstance] = useState({});
  const [endpoints, setEndpoints] = useState([]);
  const [previous, setPrevious] = useState({});
  const [selectedEndpoint, setSelectedEndpoint] = useState({});
  const [dragging, setDragging] = useState(false);
  const [config, setConfig] = useState({});
  const [deleteElements, setDeleteElements] = useState({
    pages: [],
    edges: [],
  });
  const [searchCords, setSearchCords] = useState({ x: 0, y: 0 });
  const [isTraining, setIsTraining] = useState(false);
  const nodeTypes = useMemo(() => ({ custom: CustomNode }), []);
  const edgeTypes = useMemo(() => ({ draggable: DraggableEdge }), []);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const g = new dagre.graphlib.Graph({ directed: true });
  const reactFlowWrapper = createRef();
  const nodeSize = {
    width: 172,
    height: 36,
  };
  const [userPermissions, setUserPermissions] = React.useState(false);
  let auth = useSelector((state) => state.auth);
  const location = useLocation();

  const getUserPermisions = () => {
    let user = JSON.parse(atob(auth.token.split(".")[1]));
    let hasPermissions = user.permissions.some(
      (permission) =>
        permission.name === "flows" && permission.fullAccess === true,
    );
    setUserPermissions(hasPermissions);
  };

  const flowgraphFunctions = {
    selected: false,
    handleDrag: (id, event) => {
      let egs = JSON.parse(JSON.stringify(edges));
      let instance = reactFlowInstance;
      const bounds = reactFlowWrapper.current.getBoundingClientRect();
      let pos = instance.project({
        x: event.clientX - bounds.left,
        y: event.clientY - bounds.top,
      });
      let edge = egs.find((edge) => edge.id === id);
      edge.data.labelX = pos.x;
      edge.data.labelY = pos.y;
      edge.is_modified = true;
      setEdges(egs);
    },
  };

  useEffect(() => {
    getAgentNames();
    getEndpoints();
    getConfig();
    getUserPermisions();
  }, []);

  useEffect(() => {
    if (searchCords.x && searchCords.y) {
      reactFlowInstance.setCenter(searchCords.x, searchCords.y, {
        duration: 800,
      });
    }
  }, [searchCords]);

  useEffect(() => {
    updateGraph();
  }, [nodes.length, edges.length, endpoints.length]);

  useEffect(() => {
    if (agentSelected !== "") {
      getPages();
      getAllPages();
      getIntents();
      getTraces();
    }
  }, [agentSelected]);

  useEffect(() => {
    //if editedge.data is not empty
    if (Object.keys(editEdge.data).length !== 0) {
      setOpenEditEdge(true);
    }
  }, [editEdge]);

  useEffect(() => {
    if (isTraining) {
      handleSaveGraph();
    }
  }, [isTraining]);

  const getAgentNames = () => {
    get("/agents")
      .then(async (data) => {
        let newData = orderAlphabeticallyWithAttribute(
          data.agents,
          "display_name",
        );
        const scenarioData = {
          name: location.state?.scenarioName,
        };
        let default_agent =
          scenarioData.name !== undefined
            ? scenarioData
            : defaultAgent(newData);
        let agentConfig = [];

        newData.forEach((agent) => {
          if (agent.name === default_agent.name) {
            agentConfig = agent.vars;
          }
        });
        setAgentNames(newData);
        setAgentSelected(default_agent.name);
        setAgentConfig(agentConfig);
      })
      .catch(() => {
        props.enqueueSnackbar(props.t("flowgraph:errorGettingAgentName"), {
          variant: "error",
        });
      });
  };

  const getEndpoints = () => {
    get("/endpoints")
      .then(async (data) => {
        setEndpoints(data);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingEndpoints"), {
          variant: "error",
        });
      });
  };

  const getConfig = () => {
    get("/clients/config")
      .then(async (data) => {
        let config = {};
        data.config.forEach((c) => {
          config[c.name] = c.value;
        });

        setConfig(config);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingConfig"), {
          variant: "error",
        });
      });
  };

  const getTraces = async () => {
    const tracesFlowgraph = await get("/traces/getFlow?agent=" + agentSelected);
    setTraces(tracesFlowgraph);
  };

  const getPages = () => {
    get("/flows?agent=" + agentSelected)
      .then(async (data) => {
        let layouted = createGraphLayout(data.nodes, data.edges);
        let feature = await parseFeatures(data.features, "ai_toggle");
        let previous = JSON.parse(
          JSON.stringify({ nodes: layouted.nodes, edges: layouted.edges }),
        );
        let x = 0;
        let y = 0;

        let nds = nodeTransferPage(layouted.nodes);
        setFeature(feature);
        setNodes(nds);
        setEdges(layouted.edges);
        setPrevious(previous);

        layouted.nodes.forEach((el) => {
          if (el.node_type === "input") {
            x = el.position.x + 90;
            y = el.position.y + 60;
          }
        });
        setSearchCords({ x: x, y: y });
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingPages"), {
          variant: "error",
        });
      });
  };

  const createGraphLayout = (nodes, edges) => {
    g.setGraph({ rankdir: "LR", ranker: "tight-tree" });
    g.setDefaultEdgeLabel(() => ({}));
    nodes.forEach((element) => {
      g.setNode(element.id, {
        label: element.data.label,
        width: nodeSize.width,
        height: nodeSize.height,
        message: element.data.responseMessage,
        data: {
          message: element.data.responseMessage,
          conditions: element.data.conditions,
          entity: element.data.entity,
          intents: element.data.intents,
        },
      });
    });

    edges.forEach((edge) => {
      if (edge.data && edge.data.labelX === 0) {
        let sourceNode = getNode(edge.source, nodes);
        let targetNode = getNode(edge.target, nodes);

        edge.data.labelX =
          sourceNode.position.x +
          (targetNode.position.x - sourceNode.position.x) / 2;
        edge.data.labelY =
          sourceNode.position.y +
          (targetNode.position.y - sourceNode.position.y) / 2;
      }
      g.setEdge(edge.source, edge.target, {
        label: edge.data.label,
      });
    });
    dagre.layout(g);

    return {
      nodes: nodes.map((node) => {
        const nodeLayouted = g.node(node.id);
        //establece si puede ser borrado o no
        if (
          !node.deletable &&
          (node.node_type === "input" || node.node_type === "output")
        ) {
          node.deletable = false;
        }

        if (!node.position) {
          const reactFlowBounds =
            reactFlowWrapper.current.getBoundingClientRect();
          if (node.type === "input") {
            node.position = {
              x: reactFlowBounds.right / 2 - nodeLayouted.width / 2,
              y: reactFlowBounds.bottom / 2 - nodeLayouted.height / 2,
            };
          } else {
            node.position = {
              x:
                reactFlowBounds.right / 2 -
                nodeLayouted.width / 2 +
                Math.floor(Math.random() * (300 + 300 + 1)) -
                300,
              y: reactFlowBounds.bottom / 2 - nodeLayouted.height / 2,
            };
          }
        }

        return {
          ...node,
          position: node.position,
        };
      }),
      edges: edges,
    };
  };

  const getIntents = () => {
    get("/intents?agent=" + agentSelected)
      .then(async (data) => {
        // Removemos los intents que tienen respuesta ya que no nos permiten transicionar
        let intents = data.filter(
          (intent) => intent.response?.text?.length === 0,
        );
        setIntents(intents);
        setIntentsqr(data);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingIntents"), {
          variant: "error",
        });
      });
  };

  const getAllPages = () => {
    get("/pages/all")
      .then(async (data) => {
        setPagesLean(data);
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorGettingPagesForAgentTransfers"), {
          variant: "error",
        });
      });
  };

  const onConnect = (params) => {
    params.id = "e" + params.source + "-" + params.target;
    params.data = {};
    params.data.label = intents[0].name;
    params.data.message = "";
    params.data.oid = "";
    params.data.type = "intent";
    params.type = "draggable";
    params.data.conditions = [];
    params.data.intents = [intents[0].name];
    params.data.entity = {};
    params.data.cleanParams = [];
    params.data.presets = [];
    params.labelStyle = { fill: "white" };
    params.labelBgStyle = {
      fill: "#DFBD14",
      strokeWidth: "2",
      stroke: "#006BB2",
    };
    params.style = {
      stroke: "#DFBD14",
      strokeWidth: "2px",
    };
    params.markerEnd = {
      type: MarkerType.ArrowClosed,
      width: "22px",
      height: "22px",
      color: "#DFBD14",
    }; // Flechas de transiciones

    let sourceNode = getNode(params.source);
    let targetNode = getNode(params.target);
    //if the source node is the same to target node, this if is for exit of method.
    if (sourceNode.id === targetNode.id) return;

    params.targetName = targetNode.data.label;
    params.sourceName = sourceNode.data.label;

    params.data.labelX =
      sourceNode.position.x +
      (targetNode.position.x - sourceNode.position.x) / 2;
    params.data.labelY =
      sourceNode.position.y +
      (targetNode.position.y - sourceNode.position.y) / 2;

    let edgeCount = edges.filter(
      (e) => e.source === params.source && e.target === params.target,
    ).length;

    // Si ya existe una transicion entre dos nodos, se le agrega un numero al id
    if (edgeCount > 0) {
      params.id = params.id + "-" + edgeCount;
    }
    params.is_new = true;

    setEditEdge(params);
  };

  const getNode = (id, passedNodes = undefined) => {
    let nds = JSON.parse(JSON.stringify(passedNodes ? passedNodes : nodes));
    let result = {};

    for (let i = 0; i < nds.length; i++) {
      if (nds[i].id === id) {
        result = nds[i];
        break;
      }
    }
    return result;
  };

  const handleClose = () => {
    setOpenPageDialog(false);
    setOpenEditEdge(false);
    setEditEdge({ data: {} });
  };

  const handlePublish = async () => {
    await post("/traces/publishFlow?agent=" + agentSelected)
      .then(() => {
        enqueueSnackbar(t("intents:newChangesPublish"), {
          variant: "success",
        });
        getPages();
        getAllPages();
        getIntents();
        getTraces();
      })
      .catch((errorMessage) => {
        enqueueSnackbar(
          `${errorMessage} :` + t("flowgraph:errorPublishingFlowgraph"),
          {
            variant: "error",
          },
        );
      });
  };

  const handleDelete = async () => {
    await remove("/traces/publish", {
      agent: agentSelected,
      collectionTraces: "flow",
    })
      .then((e) => {
        if (e.errors) {
          for (const err of e.errors) {
            enqueueSnackbar(err.message, {
              variant: "error",
            });
          }
        }
        getTraces();
        getPages();
      })
      .catch(() => {
        enqueueSnackbar(t("flowgraph:errorDeleteTraces"), {
          variant: "error",
        });
      });
  };

  const handleSaveEdge = (edit) => {
    let egs = JSON.parse(JSON.stringify(edges));
    let previous = JSON.parse(JSON.stringify({ nodes: nodes, edges: egs }));

    let index = egs.findIndex((x) => x.id === edit.id);
    index === -1 ? egs.push(edit) : (egs[index] = edit);

    setPrevious(previous);
    setEdges(egs);
    setOpenEditEdge(false);
    setOpenPageDialog(false);
    setEditEdge({ data: {} });
  };

  const updateGraph = () => {
    nodeTransferPage();
    setEditEdge({ data: {} });
    setEditNode({ data: {} });
  };

  const nodeTransferPage = (nodeParams = undefined) => {
    let coreTransferPage = "";
    let nds = nodeParams ? nodeParams : nodes;

    agentConfig.forEach((vars) => {
      if (vars.var_name === "core_transfer_page") {
        coreTransferPage = vars.var_value;
      }
    });

    nds.forEach((node) => {
      if (node.id === coreTransferPage) {
        node.data.core_transfer_page = true;
      } else {
        delete node.data.core_transfer_page;
      }
    });
    if (nodeParams) return nds;
    setNodes(nds);
  };

  const openDialogEdge = (event, element) => {
    let selectedElement = JSON.parse(JSON.stringify(element));

    let sourceNode = getNode(element.source);
    let targetNode = getNode(element.target);

    selectedElement.targetName = targetNode.data.label;
    selectedElement.sourceName = sourceNode.data.label;

    setEditEdge(selectedElement);
  };

  const openDialogPage = (event, element) => {
    let selectedElement = JSON.parse(JSON.stringify(element));
    let ep = selectedElement.data.endpoint;
    const noneElement = { name: "", display_name: "None" };

    // Si existe una transferencia de agente, seteamos el agente y la pagina al elemento seleccionado

    // Construct transfer agent select
    let tempAgentsArray = JSON.parse(JSON.stringify(agentNames));
    // Remove current agent from list of agents to transfer to
    let currentAgent = tempAgentsArray.find(
      (agent) => agent.name === agentSelected,
    );
    tempAgentsArray.splice(tempAgentsArray.indexOf(currentAgent), 1);
    tempAgentsArray.unshift(noneElement); // Add None item to be able to clear the component
    selectedElement.data.transfer_agent = [].concat(tempAgentsArray);

    // Construct transfer agent page select
    let tempAgentsPagesArray = JSON.parse(JSON.stringify(pagesLean));
    tempAgentsPagesArray = tempAgentsPagesArray.filter((page) =>
      page.agent === selectedElement.data.transferToAgent?.agent
        ? selectedElement.data.transferToAgent.agent
        : null,
    );
    tempAgentsPagesArray.unshift(noneElement);
    selectedElement.data.transfer_agent_pages = [].concat(tempAgentsPagesArray);

    setEditNode(selectedElement);
    setSelectedEndpoint(ep);
    setOpenPageDialog(true);
  };

  const onEdgesRemove = () => {
    reactFlowInstance.deleteElements({ edges: [editEdge] });
    setOpenEditEdge(false);
    setOpenPageDialog(false);
    setEditEdge({ data: {} });
  };

  const handleEdgesDelete2 = useCallback((deletedEdges) => {
    const updatedDeleteElements = {
      ...deleteElements,
      edges: [...deleteElements.edges, ...deletedEdges],
    };
    setDeleteElements(updatedDeleteElements);
  }, [deleteElements]);

  const onElementsRemove = (elementsToRemove) => {
    let nds = JSON.parse(JSON.stringify(nodes));
    let eds = JSON.parse(JSON.stringify(edges));
    let tmpDeleteElements = JSON.parse(JSON.stringify(deleteElements));

    let previous = JSON.parse(JSON.stringify({ nodes: nodes, edges: edges }));
    setPrevious(previous);

    for (let x = 0; x < nds.length; x++) {
      for (let i = 0; i < elementsToRemove.length; i++) {
        // Prevenir eliminar el estado inicial (input)
        if (
          elementsToRemove[i].id === nds[x].id &&
          elementsToRemove[i].node_type !== "input" &&
          elementsToRemove[i].node_type !== "output"
        ) {
          for (let j = 0; j < eds.length; j++) {
            if (
              elementsToRemove[i].id === eds[j].source ||
              elementsToRemove[i].id === eds[j].target
            ) {
              tmpDeleteElements.edges.push(eds[j]);
              eds.splice(j, 1);
            }
          }
          tmpDeleteElements.pages.push(elementsToRemove[i]);
          nds.splice(x, 1);
        }
      }
    }
    setNodes(nds);
    setEdges(eds);
    setOpenEditEdge(false);
    setOpenPageDialog(false);
    setSelectedEndpoint(undefined);
    setDeleteElements(tmpDeleteElements);
  };

  const handleDeleteEdge = () => {
    let deleteElementsTmp = JSON.parse(JSON.stringify(deleteElements));
    deleteElementsTmp.edges.push(editEdge);
    setDeleteElements(deleteElementsTmp);
    onEdgesRemove([editEdge]);
  };

  const handleDeletePage = () => {
    onElementsRemove([editNode]);
  };

  const handleSaveNode = (edit) => {
    let nds = JSON.parse(JSON.stringify(nodes));
    let firstIfFulfilled = false;
    let num;
    let messageDisplayed = false;
    for (let i = 0; i < nds.length; i++) {
      if (edges[i]?.source === edit.id && edit.data.transferToAgent?.agent) {
        if (!messageDisplayed) {
          props.enqueueSnackbar(props.t("flowgraph:ErrorHasTransitions"), {
            variant: "error",
          });
          messageDisplayed = true;
        }
        firstIfFulfilled = true;
      }
      if (nds[i].id === edit.id) {
        num = i;
      }
    }
    if (!firstIfFulfilled) {
      nds[num] = edit;
      nds[num].selected = false;
    }

    // En este caso, previous va a setear 2 pasos atrás para solucionar un bug (this.state.nodes y elements llega siempre actualizado)
    // Seteamos elements a [] para arreglar bug de re-renderizado en actualizacion de label
    // PUEDE SER QUE SUCEDA ESTE BUG DEVUELTA DSP DE CAMBIARA A FUNCIONAL
    setPrevious(previous);
    setOpenPageDialog(false);
    setOpenEditEdge(false);
    setEditNode({ data: {} });
    setNodes(nds);
  };

  const onInit = (reactFlowInstance) => {
    //console.log("Flowgraph loaded:", reactFlowInstance);
    setReactFlowInstance(reactFlowInstance);
  };

  const handleRevertGraph = () => {
    setEditNode({ data: {} });
    setEditEdge({ data: {} });
    getEndpoints();
    getConfig();
    getPages();
  };

  const handleUndo = () => {
    let prevs = JSON.parse(JSON.stringify(previous));
    if (prevs.nodes.length > 0 && prevs.edges.length) {
      let nodes = prevs.nodes.filter((x) => !x.source);
      let edges = prevs.edges.filter((x) => x.source);
      setNodes(nodes);
      setEdges(edges);
      setEditEdge({ data: {} });
      setEditNode({ data: {} });
    }
  };

  const handleSaveGraph = () => {
    post("/flows", {
      agent: agentSelected,
      elements: nodes.concat(edges),
      endpoints: endpoints,
      deleteElements: deleteElements,
      self_service: config.self_service,
    })
      .then(async (response) => {
        if (response) {
          setIsTraining(false);
          props.enqueueSnackbar(props.t("flowgraph:graphSaved"), {
            variant: "success",
          });
          getTraces();
          setDeleteElements({ pages: [], edges: [] });
        } else {
          setIsTraining(false);
          props.enqueueSnackbar(props.t("flowgraph:featureDisabled"), {
            variant: "error",
          });
        }
      })
      .catch(() => {
        setIsTraining(false);
        props.enqueueSnackbar(props.t("flowgraph:errorGettingPages"), {
          variant: "error",
        });
      });
  };

  const handleNewNode = () => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    let previous = JSON.parse(JSON.stringify({ nodes: nodes, edges: edges }));
    setPrevious(previous);

    let position = reactFlowInstance.project({
      x:
        reactFlowBounds.right / 2 +
        Math.floor(Math.random() * (120 - 10 + 1) + 10),
      y:
        reactFlowBounds.bottom / 2 +
        Math.floor(Math.random() * (120 - 10 + 1) + 10),
    });

    let lastId = Math.floor(Math.random() * 10000).toString();
    const newNode = {
      id: newObjectId(),
      type: "custom",
      data: {
        label: "New node" + lastId,
        message: "",
      },
      position,
    };
    newNode.data.oid = newNode.id;
    newNode.is_new = true;
    let nds = JSON.parse(JSON.stringify(nodes)).concat(newNode);
    setNodes(nds);
    setOpenPageDialog(false);
    setOpenEditEdge(false);
  };

  const handleChangeAgent = async (e) => {
    let agentConfig = [];
    if (e.target.value !== "") {
      agentNames.forEach((agent) => {
        if (agent.name === e.target.value) {
          agentConfig = agent.vars;
        }
      });
    }
    setAgentSelected(e.target.value);
    setAgentConfig(agentConfig);
    setNodes([]);
    setEdges([]);
  };

  // DRAGGABLE EDGE AND SELECTION FUNCTIONS

  const onDragStop = () => {
    if (selectedEdges.length === 0) {
      setSelectedEdges([]);
    }
    setDragging(false);
  };

  const onNodeDrag = (event, selectedNodes) => {
    if (dragging) {
      let nds = JSON.parse(JSON.stringify(nodes));
      let eds = JSON.parse(JSON.stringify(edges));
      let dx = 0;
      let dy = 0;

      selectedNodes.forEach((selected) => {
        nds.forEach((node) => {
          if (node.id === selected.id) {
            if (dx === 0) {
              dx = selected.position.x - node.position.x;
            }
            if (dy === 0) {
              dy = selected.position.y - node.position.y;
            }
            node.position = selected.position;
            node.is_modified = true;
          }
        });
      });

      if (selectedEdges && selectedEdges.length > 0) {
        let edgeUpdate = JSON.parse(JSON.stringify(selectedEdges));

        edgeUpdate.forEach((selectedEdge) => {
          eds.forEach((edge) => {
            if (edge.id === selectedEdge.id) {
              edge.data.labelX = edge.data.labelX + dx;
              edge.data.labelY = edge.data.labelY + dy;
            }
          });
        });
      }
      setNodes(nds);
      setEdges(eds);
    }
  };

  const onSelection = (update) => {
    if (selectedEdges !== update.edges) {
      setSelectedEdges(update.edges);
    }
  };

  const validateLimit = useMemo(() => {
    if (config.self_service && config.self_service === "true") {
      if (nodes.length >= config.self_service_nodes_limit) {
        return true;
      }
    }
    return false;
  }, [config, nodes]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: theme.palette.flowgraphBg,
      }}>
      <NodeModal
        agent={agentSelected}
        open={openPageDialog}
        onClose={handleClose}
        editNodeProps={editNode}
        previous={previous}
        onSave={handleSaveNode}
        onDelete={handleDeletePage}
        featureProps={feature}
        endpointProps={selectedEndpoint}
        endpoints={endpoints}
        nodes={nodes}
        pagesLean={pagesLean}
        intents={intentsqr}
        userPermissions={userPermissions}
      />
      <EdgeModal
        open={openEditEdge}
        onClose={handleClose}
        transitions={edges}
        editEdge={editEdge}
        previous={previous}
        onSave={handleSaveEdge}
        onDelete={handleDeleteEdge}
        intents={intents}
        edges={edges}
        pages={nodes}
        userPermissions={userPermissions}
      />
      <FlowgraphContext.Provider value={flowgraphFunctions}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onEdgeDoubleClick={openDialogEdge}
          onNodeDoubleClick={openDialogPage}
          onInit={onInit}
          onNodesDelete={onElementsRemove}
          onEdgesDelete={handleEdgesDelete2}
          deleteKeyCode={"Delete"}
          multiSelectionKeyCode={"ControlLeft"}
          snapToGrid={true}
          ref={reactFlowWrapper}
          selectionMode={"partial"}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          snapGrid={[15, 15]}
          connectionRadius={30}
          selectNodesOnDrag={false}
          // DRAGGABLE EDGE AND SELECTION FUNCTIONS
          onNodeDragStart={() => {
            setDragging(true);
          }}
          onNodeDragStop={onDragStop}
          onNodeDrag={(event, node, nodes) => onNodeDrag(event, nodes)}
          //
          onSelectionChange={onSelection}
          onSelectionDrag={onNodeDrag}
          //
          // eslint-disable-next-line react/jsx-no-duplicate-props
        >
          <Background color="#aaa" variant="dots" gap={12} size={1} />
        </ReactFlow>
      </FlowgraphContext.Provider>
      <Tooltip
        title={
          !userPermissions
            ? props.t("flowgraph:noPermissionTooltip")
            : props.t("flowgraph:undoAll")
        }
        sx={{
          backgroundColor: !userPermissions ? "disabledFab.color" : "primary",
          "&:hover": {
            backgroundColor: !userPermissions ? "disabledFab.color" : "primary",
          },
          opacity: !userPermissions ? "0.7" : "1",
        }}>
        <Fab
          className="revert"
          color="primary"
          aria-label="revert changes"
          onClick={!userPermissions ? null : handleRevertGraph}>
          <RotateLeftIcon />
        </Fab>
      </Tooltip>
      <Tooltip
        title={
          !userPermissions
            ? props.t("flowgraph:noPermissionTooltip")
            : props.t("flowgraph:undo")
        }
        sx={{
          backgroundColor: !userPermissions ? "disabledFab.color" : "primary",
          "&:hover": {
            backgroundColor: !userPermissions ? "disabledFab.color" : "primary",
          },
          opacity: !userPermissions ? "0.7" : "1",
        }}>
        <Fab
          className="undo"
          color="primary"
          aria-label="undo changes"
          onClick={!userPermissions ? null : handleUndo}>
          <UndoIcon />
        </Fab>
      </Tooltip>
      <Tooltip
        title={
          !userPermissions
            ? props.t("flowgraph:noPermissionTooltip")
            : validateLimit
              ? props.t("flowgraph:addNodeDisabledLimit", {
                limit: config.self_service_nodes_limit,
              })
              : props.t("flowgraph:addNode")
        }>
        <Fab
          className="new-node"
          color="primary"
          aria-label="add"
          sx={{
            backgroundColor:
              validateLimit || !userPermissions
                ? "disabledFab.color"
                : "primary",
            "&:hover": {
              backgroundColor:
                validateLimit || !userPermissions
                  ? "disabledFab.color"
                  : "primary",
            },
            opacity: validateLimit || !userPermissions ? "0.7" : "1",
          }}
          onClick={validateLimit || !userPermissions ? null : handleNewNode}>
          <AddIcon />
        </Fab>
      </Tooltip>
      <Tooltip
        title={
          !userPermissions
            ? props.t("flowgraph:noPermissionTooltip")
            : props.t("flowgraph:save")
        }>
        <Fab
          className="save"
          color="primary"
          aria-label="save"
          onClick={
            !userPermissions
              ? null
              : !isTraining
                ? () => {
                  setIsTraining(true);
                }
                : null
          }
          sx={{
            backgroundColor:
              isTraining || !userPermissions ? "disabledFab.color" : "primary",
            "&:hover": {
              backgroundColor:
                isTraining || !userPermissions
                  ? "disabledFab.color"
                  : "primary",
            },
            opacity: isTraining || !userPermissions ? "0.7" : "1",
          }}>
          <SaveIcon />
        </Fab>
      </Tooltip>
      <ButtonGroup
        className="zoom-group"
        variant="contained"
        orientation="vertical">
        <Tooltip title={props.t("flowgraph:zoomIn")} placement="right">
          <Button
            className="zoom-in"
            color="primary"
            aria-label="zoom-in"
            sx={{
              borderRadius: "90px",
            }}
            onClick={() => reactFlowInstance.zoomIn({ duration: 600 })}>
            <ZoomInIcon />
          </Button>
        </Tooltip>
        <Tooltip title={props.t("flowgraph:zoomOut")} placement="right">
          <Button
            className="zoom-out"
            color="primary"
            aria-label="zoom-out"
            sx={{
              borderRadius: "90px",
            }}
            onClick={() => reactFlowInstance.zoomOut({ duration: 600 })}>
            <ZoomOutIcon />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Autocomplete
        key={agentSelected}
        id="autocomplete-search-page"
        className="autocomplete-search-page"
        options={nodes.sort((a, b) =>
          a.data.label.toUpperCase() > b.data.label.toUpperCase() ? 1 : -1,
        )}
        getOptionLabel={(option) => option.data.label}
        sx={{
          width: 200,
          backgroundColor: "white",
          borderRadius: "10px",
          "& .MuiAutocomplete-inputRoot": {
            color: "#000",
          },
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            setSearchCords(newValue.position);
            reactFlowInstance.setCenter(
              newValue.position.x,
              newValue.position.y,
              {
                duration: 800,
              },
            );
          }
        }}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            reactFlowInstance.setCenter(searchCords.x, searchCords.y, {
              duration: 800,
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.t("flowgraph:search")}
            variant="outlined"
            fullWidth
            sx={{
              "& fieldset": { border: "none" },
              "& .MuiSvgIcon-root": {
                color: "#000",
              },
              "& label": {
                color: "#737272",
              },
            }}
          />
        )}
      />
      <Autocomplete
        key={agentSelected}
        id="autocomplete-search-message"
        className="autocomplete-search-message"
        options={
          nodes
            .filter(
              (option) =>
                option.data.responseMessage &&
                option.data.responseMessage[0] !== "" &&
                option.data.responseMessage.length > 0,
            )
            .sort((a, b) =>
              a.data.responseMessage[0].toUpperCase() >
              b.data.responseMessage[0].toUpperCase()
                ? 1
                : -1,
            ) // Luego, ordena las opciones filtradas
        }
        placeholder={props.t("flowgraph:messages")}
        getOptionLabel={(option) => option.data.responseMessage.join(" ")}
        sx={{
          width: 200,
          marginTop: 8,
          backgroundColor: "white",
          borderRadius: "10px",
          "& .MuiAutocomplete-inputRoot": {
            color: "#000",
          },
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            setSearchCords(newValue.position);
            reactFlowInstance.setCenter(
              newValue.position.x,
              newValue.position.y,
              {
                duration: 800,
              },
            );
          }
        }}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            reactFlowInstance.setCenter(searchCords.x, searchCords.y, {
              duration: 800,
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.t("flowgraph:messages")}
            variant="outlined"
            fullWidth
            sx={{
              "& fieldset": { border: "none" },
              "& .MuiSvgIcon-root": {
                color: "#000",
              },
              "& label": {
                color: "#737272",
              },
            }}
          />
        )}
      />
      <div className="agent-select">
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          {props.t("flowgraph:agent")}
        </InputLabel>
        <FormControl fullWidth>
          <Select
            id="combo-agent"
            value={agentSelected}
            onChange={handleChangeAgent}>
            {agentNames.map((item, index) => (
              <MenuItem
                name={item.display_name}
                value={item.name}
                key={index}
                id={`menu-item-${index}`}>
                <ListItemText primary={item.display_name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <TracesHistory
        handleDelete={handleDelete}
        handlePublish={handlePublish}
        agentPages={agentSelected}
        traces={traces}
      />
    </div>
  );
};

export default withTranslation()(withSnackbar(Flowgraph));
